import Component from "vue-class-component";
import {
  DgButton,
  DgCheckbox,
  DgColumn,
  DgDynamicInput,
  DgFileInput,
  DgFlex,
  DgGridContainer,
  DgText,
  DgTextInput,
  DynamicField,
  SelectOption,
} from "@dasgate/uikit";
import { ImageResizer } from "@/legacy/models/imageResizer";
import { isUndefined } from "@/core/shared/utils";
import { base64Encoder } from "@/ui/_utils/base64Encoder";
import { RegisterType, SingleDynamicDocumentData } from "@/legacy/models/shared";
import { FieldValidator, validateEmail } from "@/legacy/models/validators";
import EventBus, { EnumEventBus } from "@/legacy/services/bus.service";
import { DynamicRegisterType } from "@/legacy/models/settings";
import Vue from "vue";
import DynamicForm from "@/legacy/models/dynamicForm";

const MAX_IMAGE_LENGTH = 6990508;
const MIN_IMAGE_LENGTH = 6828;
const DESIRED_IMAGE_LENGTH = 6500000;

type RegisterFormOnlySelfieWithId = "email" | "selfie";

@Component({
  components: {
    DgCheckbox,
    DgColumn,
    DgTextInput,
    DgText,
    DgFileInput,
    DgButton,
    DgDynamicInput,
    DgGridContainer,
    DgFlex,
  },
})
export default class Dynamic extends Vue {
  public registerType = RegisterType.Single;
  public dynamicForm = new DynamicForm(this.registerType);
  public validationErrors: { [id: string]: string } = { selfie: "", email: "" };
  public data: SingleDynamicDocumentData = {
    fields: {},
    deliver_by_mail: false,
    email: "",
    selfie: "",
  };
  public validator = new FieldValidator();
  public loading = false;
  public legalTermsAccepted = false;
  private selfieRequiredFields: RegisterFormOnlySelfieWithId[] = ["selfie"];
  private imageResizer = new ImageResizer();
  protected formChanged = false;

  public mounted() {
    EventBus.$on(EnumEventBus.serverErrors, (errors: { [id: string]: string }) => {
      Object.keys(errors).forEach(field => {
        this.$set(this.validationErrors, field, this.$tc(errors[field]));
      });
      this.loading = false;
    });
  }

  public get biometricQR(): boolean {
    return this.$appStore.getters.settings.biometricQR;
  }

  public get fields(): DynamicRegisterType[] {
    return this.dynamicForm.fields;
  }

  public get requiredFields(): string[] {
    return this.dynamicForm.requiredFields.concat(this.selfieRequiredFields);
  }

  public get canSubmit(): boolean {
    if (!this.legalTermsAccepted) {
      return false;
    }

    if (!this.formChanged) {
      return false;
    }

    // check dynamic required fields
    for (const requiredField of this.dynamicForm.requiredFields) {
      if (!this.data.fields[requiredField]) {
        return false;
      }
    }
    // check base fields
    for (const requiredField of this.selfieRequiredFields) {
      if (!this.data[requiredField]) {
        return false;
      }
    }

    const errors = Object.values(this.validationErrors);
    const thereAreErrors = errors.some(e => e !== "");
    if (thereAreErrors) {
      return false;
    }

    return true;
  }

  public get dynamicFields(): DynamicField[] {
    return this.dynamicForm.fields.map(
      field =>
        ({
          label: this.$t("common.fields." + field.label),
          uppercase: field.uppercase,
          placeholder: field.placeholder ? this.$t("common.placeholders." + field.placeholder) : "",
          autocomplete: false,
          error: this.validationErrors[field.name],
          name: field.name,
          type: field.type,
          required: field.required,
          selectOptions: this.getTranslatedOptions(field.selectOptions),
        } as DynamicField)
    );
  }

  public async setSelfie(file: File) {
    this.clearImageData();
    try {
      const encodedFile = await base64Encoder.encode(file);

      if (encodedFile.length < MIN_IMAGE_LENGTH) {
        this.validationErrors.selfie = this.$t("common.errors.image.small").toString();
        return;
      } else if (encodedFile.length > MAX_IMAGE_LENGTH) {
        const scaleRatio = DESIRED_IMAGE_LENGTH / encodedFile.length;
        this.data.selfie = await this.imageResizer.resizeBase64Image(encodedFile, scaleRatio);
      } else {
        this.data.selfie = encodedFile;
      }
    } catch {
      this.clearImageData();
      this.validationErrors.selfie = this.$t("common.errors.image.upload").toString();
    }
  }

  public toggleShowEmail(): void {
    this.data.deliver_by_mail = !this.data.deliver_by_mail;
    if (this.data.deliver_by_mail) {
      this.selfieRequiredFields.push("email");
      if (this.data.email.length) {
        this.validate("email");
      }
    } else {
      this.selfieRequiredFields = this.selfieRequiredFields.filter(obj => obj !== "email");
      this.validationErrors.email = "";
    }
  }

  public async change(field: string) {
    this.formChanged = true;
    await this.validate(field);
  }

  public async validate(field: string | RegisterFormOnlySelfieWithId) {
    // Dynamic fields validation
    const dynamicField = this.fields.find(x => x.name === field);
    let error = "";
    if (dynamicField) {
      error = this.$tc(await this.dynamicForm.validate(field, this.data.fields[field]));
    } else {
      // base fields validation
      if (this.requiredFields.indexOf(field) >= 0) {
        error = this.data[field as RegisterFormOnlySelfieWithId] ? "" : this.$t("common.errors.required").toString();
      }

      if (field === "email") {
        error = this.$tc(await validateEmail(this.data[field])).toString();
      }
    }
    this.$set(this.validationErrors, field, error);
  }

  public onAccept() {
    this.$emit("on-accept", this.data);
  }

  public onBack() {
    this.$emit("on-back");
  }

  private getTranslatedOptions(selectOptions: SelectOption[]) {
    if (isUndefined(selectOptions)) {
      return undefined;
    }

    return selectOptions.map(({ value, label }) => ({ label: this.$t("common.fields." + label), value }));
  }

  private clearImageData() {
    this.data.selfie = "";
    this.validationErrors.selfie = "";
  }
}
