import Vue from "vue";
import Component from "vue-class-component";
import { IdentityPortrayalType, RegisterType, SingleDynamicDocumentData } from "@/legacy/models/shared";
import { isDefined } from "@/core/shared/utils";
import { DgColumn, DgText } from "@dasgate/uikit";
import { StaticForm } from "./_components/StaticForm";
import { DynamicForm } from "./_components/DynamicForm";
import { RouteNames } from "@/ui/_router";
import { DelegatedNoDocumentDataWithId } from "@/legacy/features/portal/models/delegated-onboarding.models";

@Component({
  components: {
    DgText,
    DgColumn,
    StaticForm,
    DynamicForm,
  },
})
export default class SingleRegistration extends Vue {
  public get isDynamicForm(): boolean {
    return isDefined(this.$appStore.getters.settings.registerTypes[RegisterType.Single]);
  }

  public async cancel(): Promise<void> {
    this.$router.push({ name: RouteNames.PortalManagement });
  }

  public async accept(data: DelegatedNoDocumentDataWithId | SingleDynamicDocumentData): Promise<void> {
    const result = await this.$services.delegatedOnboardingService.single({
      data,
      type: IdentityPortrayalType.selfie,
      acsIdSchema: this.$appStore.getters.settings.acsIdSchema,
    });
    if (result.success) {
      this.$router.push({ name: RouteNames.DelegatedFinishRegister });
    } else {
      this.$router.push({
        name: RouteNames.FinishRegisterWithError,
        params: { errorCode: result.code.toString() },
      });
    }
  }
}
