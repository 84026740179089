import { Log } from "@/legacy/infrastructure/logger";

export class ImageResizer {
  public resizeBase64Image(imgsrc: string, scaleRatio: number): Promise<string> {
    return new Promise(resolve => {
      const img = new Image();

      img.onload = () => {
        let canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d")!;
        const maxSize = 0.8;
        ctx.save();
        ctx.drawImage(img, 0, 0);
        ctx.restore();
        // Let's find the max available width for scaled image
        const ratio = canvas.width / canvas.height;
        const maxWidth = 3000;
        let mWidth = maxWidth;

        if (maxSize > 0 && maxSize < (canvas.width * canvas.height) / 1000000) {
          const mSize = Math.floor(Math.sqrt(maxSize * ratio) * 1000);
          mWidth = mWidth > 0 ? Math.min(mWidth, mSize) : mSize;
        }
        if (scaleRatio) {
          mWidth = Math.min(mWidth, Math.floor(scaleRatio * canvas.width));
        }

        if (canvas.width === 0 || canvas.height === 0) {
          return "";
        }

        Log.info("ImageUploader: original image size = " + canvas.width + " X " + canvas.height);
        Log.info("ImageUploader: scaled image size = " + mWidth + " X " + Math.floor(mWidth / ratio));
        if (mWidth <= 0) {
          mWidth = 1;
          Log.warn("ImageUploader: image size is too small");
        }
        // simple resize with a 2:1 ratio
        while (canvas.width >= 2 * mWidth) {
          canvas = this.getHalfScaleCanvas(canvas);
        }
        // When factor less than 2:1 remains, finish up with alogorithm
        if (canvas.width > mWidth) {
          canvas = this.scaleCanvasWithAlgorithm(canvas, mWidth);
        }

        resolve(canvas.toDataURL());
      };
      img.src = imgsrc;
    });
  }

  public getHalfScaleCanvas(canvas: any) {
    const halfCanvas = document.createElement("canvas");
    halfCanvas.width = canvas.width / 2;
    halfCanvas.height = canvas.height / 2;
    halfCanvas.getContext("2d")!.drawImage(canvas, 0, 0, halfCanvas.width, halfCanvas.height);
    return halfCanvas;
  }

  public scaleCanvasWithAlgorithm(canvas: any, maxWidth: any) {
    const scaledCanvas = document.createElement("canvas");
    const scale = maxWidth / canvas.width;
    scaledCanvas.width = canvas.width * scale;
    scaledCanvas.height = canvas.height * scale;
    const srcImgData = canvas.getContext("2d")!.getImageData(0, 0, canvas.width, canvas.height);
    const destImgData = scaledCanvas.getContext("2d")!.createImageData(scaledCanvas.width, scaledCanvas.height);
    this.applyBilinearInterpolation(srcImgData, destImgData, scale);
    scaledCanvas.getContext("2d")!.putImageData(destImgData, 0, 0);
    return scaledCanvas;
  }

  public applyBilinearInterpolation(srcCanvasData: any, destCanvasData: any, scale: any) {
    function inner(f00: any, f10: any, f01: any, f11: any, x: any, y: any) {
      const unX = 1.0 - x;
      const unY = 1.0 - y;
      return f00 * unX * unY + f10 * x * unY + f01 * unX * y + f11 * x * y;
    }
    let i;
    let j;
    let iyv;
    let iy0;
    let iy1;
    let ixv;
    let ix0;
    let ix1;
    let idxD;
    let idxS00;
    let idxS10;
    let idxS01;
    let idxS11;
    let dx;
    let dy;
    let r;
    let g;
    let b;
    let a;
    for (i = 0; i < destCanvasData.height; ++i) {
      iyv = i / scale;
      iy0 = Math.floor(iyv);
      // Math.ceil can go over bounds
      iy1 = Math.ceil(iyv) > srcCanvasData.height - 1 ? srcCanvasData.height - 1 : Math.ceil(iyv);
      for (j = 0; j < destCanvasData.width; ++j) {
        ixv = j / scale;
        ix0 = Math.floor(ixv);
        // Math.ceil can go over bounds
        ix1 = Math.ceil(ixv) > srcCanvasData.width - 1 ? srcCanvasData.width - 1 : Math.ceil(ixv);
        idxD = (j + destCanvasData.width * i) * 4;
        // matrix to vector indices
        idxS00 = (ix0 + srcCanvasData.width * iy0) * 4;
        idxS10 = (ix1 + srcCanvasData.width * iy0) * 4;
        idxS01 = (ix0 + srcCanvasData.width * iy1) * 4;
        idxS11 = (ix1 + srcCanvasData.width * iy1) * 4;
        // overall coordinates to unit square
        dx = ixv - ix0;
        dy = iyv - iy0;
        // I let the r, g, b, a on purpose for debugging
        r = inner(
          srcCanvasData.data[idxS00],
          srcCanvasData.data[idxS10],
          srcCanvasData.data[idxS01],
          srcCanvasData.data[idxS11],
          dx,
          dy
        );
        destCanvasData.data[idxD] = r;
        g = inner(
          srcCanvasData.data[idxS00 + 1],
          srcCanvasData.data[idxS10 + 1],
          srcCanvasData.data[idxS01 + 1],
          srcCanvasData.data[idxS11 + 1],
          dx,
          dy
        );
        destCanvasData.data[idxD + 1] = g;
        b = inner(
          srcCanvasData.data[idxS00 + 2],
          srcCanvasData.data[idxS10 + 2],
          srcCanvasData.data[idxS01 + 2],
          srcCanvasData.data[idxS11 + 2],
          dx,
          dy
        );
        destCanvasData.data[idxD + 2] = b;
        a = inner(
          srcCanvasData.data[idxS00 + 3],
          srcCanvasData.data[idxS10 + 3],
          srcCanvasData.data[idxS01 + 3],
          srcCanvasData.data[idxS11 + 3],
          dx,
          dy
        );
        destCanvasData.data[idxD + 3] = a;
      }
    }
  }
}
