import Vue from "vue";
import Component from "vue-class-component";
import {
  DgButton,
  DgCheckbox,
  DgCircularProgressBar,
  DgColumn,
  DgDivider,
  DgFileInput,
  DgFlex,
  DgModal,
  DgRow,
  DgText,
} from "@dasgate/uikit";
import { RouteNames } from "@/ui/_router";
import EventBus, { EnumEventBus } from "@/legacy/services/bus.service";

@Component({
  components: {
    DgButton,
    DgCircularProgressBar,
    DgColumn,
    DgCheckbox,
    DgDivider,
    DgFileInput,
    DgFlex,
    DgModal,
    DgRow,
    DgText,
  },
})
export default class BatchRegistration extends Vue {
  public validationErrors: { [id: string]: string } = {};
  public legalTermsAccepted = false;
  protected progress = 0;
  public loading = false;
  protected onboardingFile: File = {} as File;
  protected mediaFile: File = {} as File;
  public deliverByEmail = false;

  public mounted() {
    EventBus.$on(EnumEventBus.serverErrors, (errors: { [id: string]: string }) => {
      Object.keys(errors).forEach(field => {
        this.$set(this.validationErrors, field, this.$tc(errors[field]));
      });
      this.loading = false;
    });
  }

  public get canSubmit(): boolean {
    return !!this.onboardingFile.name && !!this.mediaFile.name && this.legalTermsAccepted;
  }

  private get acsIdSchema(): string {
    return this.$appStore.getters.settings.acsIdSchema;
  }
  public get biometricQR(): boolean {
    return this.$appStore.getters.settings.biometricQR;
  }

  get progressValue(): number {
    return Math.trunc(this.progress);
  }

  public onBack() {
    this.$router.push({ name: RouteNames.PortalManagement });
  }

  public setOnboardingFile(file: File) {
    this.validationErrors.metadata = "";
    this.onboardingFile = file;
  }

  public setMediaFile(file: File) {
    this.validationErrors.media = "";
    this.mediaFile = file;
  }

  public toggleDeliverByEmail(): void {
    this.deliverByEmail = !this.deliverByEmail;
  }

  public toggleLegalTerms(): void {
    this.legalTermsAccepted = !this.legalTermsAccepted;
  }

  public async upload(): Promise<void> {
    EventBus.$on("batchUploadProgress", (progress: number) => (this.progress = progress));
    this.loading = true;
    const response = await this.$services.delegatedOnboardingService.batch({
      onboardingFile: this.onboardingFile,
      mediaFile: this.mediaFile,
      deliverByEmail: this.deliverByEmail,
      acsIdSchema: this.acsIdSchema,
    });
    EventBus.$off("batchUploadProgress");
    this.loading = false;

    if (response.success) {
      this.$router.push({
        name: RouteNames.BatchRegistrationResult,
        params: { batchId: response.batchId },
      });
    } else {
      this.$services.notificationService.error(this.$tc("common.portal.batch-register.errors." + response.error));
    }
  }
}
