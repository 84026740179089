import Vue from "vue";
import Component from "vue-class-component";
import {
  DgButton,
  DgCheckbox,
  DgColumn,
  DgFileInput,
  DgFlex,
  DgGridContainer,
  DgLoading,
  DgText,
  DgTextInput,
} from "@dasgate/uikit";
import { ImageResizer } from "@/legacy/models/imageResizer";
import { base64Encoder } from "@/ui/_utils/base64Encoder";
import {
  DelegatedNoDocumentDataWithId,
  DelegatedOnboardingImageConstraints,
} from "@/legacy/features/portal/models/delegated-onboarding.models";
import { validateEmail } from "@/legacy/models/validators";

type RegisterFormOnlySelfieWithId = "username" | "acs_id" | "email" | "image";

@Component({
  components: {
    DgText,
    DgColumn,
    DgButton,
    DgTextInput,
    DgCheckbox,
    DgFileInput,
    DgGridContainer,
    DgLoading,
    DgFlex,
  },
})
export default class StaticForm extends Vue {
  private imageResizer = new ImageResizer();
  private baseValidationErrors: { [id: string]: string } = {
    username: "",
    acs_id: "",
    email: "",
    image: "",
  };
  public data: DelegatedNoDocumentDataWithId = {
    username: "",
    acs_id: "",
    id: "",
    email: "",
    image: "",
    deliver_by_mail: false,
  };
  public requiredFields: RegisterFormOnlySelfieWithId[] = ["username", "acs_id", "image"];
  public showEmail = false;
  protected formChanged = false;

  public get validationErrors() {
    const errors = this.$services.requestService.getErrors();
    Object.keys(errors).forEach(key => {
      this.baseValidationErrors[key] = errors[key];
    });

    return this.baseValidationErrors;
  }

  public get biometricQR(): boolean {
    return this.$appStore.getters.settings.biometricQR;
  }

  public get loading(): boolean {
    return this.$appStore.getters.loading;
  }

  public get canSubmit(): boolean {
    for (const requiredField of this.requiredFields) {
      if (!this.data[requiredField]) {
        return false;
      }
    }

    if (!this.formChanged) {
      return false;
    }

    const errors = Object.values(this.validationErrors);
    const thereAreErrors = errors.some(e => e !== "");
    if (thereAreErrors) {
      return false;
    }

    return true;
  }

  public async setImage(file: File) {
    this.clearImageData();
    try {
      const encodedFile = await base64Encoder.encode(file);

      if (encodedFile.length < DelegatedOnboardingImageConstraints.MinImageLength) {
        this.validationErrors.image = this.$t("common.errors.image.small").toString();
        return;
      } else if (encodedFile.length > DelegatedOnboardingImageConstraints.MaxImageLength) {
        const scaleRatio = DelegatedOnboardingImageConstraints.DesiredImageLength / encodedFile.length;
        this.data.image = await this.imageResizer.resizeBase64Image(encodedFile, scaleRatio);
      } else {
        this.data.image = encodedFile;
      }
    } catch {
      this.clearImageData();
      this.validationErrors.image = this.$t("common.errors.image.upload").toString();
    }
  }

  public toggleShowEmail(): void {
    this.showEmail = !this.showEmail;
    if (this.showEmail) {
      this.requiredFields.push("email");
      this.data.deliver_by_mail = true;
      if (this.data.email.length) {
        this.validate("email");
      }
    } else {
      this.requiredFields = this.requiredFields.filter(i => i !== "email");
      this.data.deliver_by_mail = false;
      this.validationErrors.email = "";
    }
  }

  public async change(field: RegisterFormOnlySelfieWithId) {
    this.formChanged = true;
    await this.validate(field);
  }

  public async validate(field: RegisterFormOnlySelfieWithId): Promise<void> {
    if (this.requiredFields.indexOf(field) >= 0) {
      this.validationErrors[field] = this.data[field] ? "" : this.$t("common.errors.required").toString();
    }
    if (field === "email") {
      this.validationErrors[field] = this.$tc(await validateEmail(this.data[field])).toString();
    }

    if (field === "acs_id") {
      if (
        await this.$services.delegatedOnboardingService.acsidExists({
          acsid: this.data[field],
        })
      ) {
        this.validationErrors[field] = this.$t("common.errors.acsid_invalid").toString();
      }
    }
  }

  public onAccept() {
    this.$emit("on-accept", this.data);
  }

  public onBack() {
    this.$emit("on-back");
  }

  private clearImageData() {
    this.data.image = "";
    this.validationErrors.image = "";
  }
}
