import Component from "vue-class-component";
import {
  DgButton,
  DgCircularProgressBar,
  DgColumn,
  DgDivider,
  DgRow,
  DgTable,
  DgText,
  DgTextButton,
  IconName,
} from "@dasgate/uikit";
import { Prop } from "vue-property-decorator";
import { BatchProgress, FailedBatchOnboardings } from "@/legacy/features/portal/models/delegated-onboarding.models";
import Vue from "vue";

const intervalInMilliseconds = 2000;

@Component({
  components: {
    DgButton,
    DgCircularProgressBar,
    DgColumn,
    DgDivider,
    DgRow,
    DgTable,
    DgText,
    DgTextButton,
  },
})
export default class BatchRegistrationResult extends Vue {
  @Prop({ required: true, default: "" }) public batchId!: string;

  public progress: BatchProgress = {
    total: 0,
    failed: 0,
    completed: 0,
    rejected: 0,
  };
  public failedOnboardings: FailedBatchOnboardings[] = [];
  public status = "";
  public batchCompleted = false;
  public dataLoaded = false;
  public showErrors = false;
  private intervalId: number | undefined;
  private timeoutId: number | undefined;

  public mounted(): void {
    this.status = this.$tc("common.working");
    this.intervalId = window.setInterval(() => {
      this.pullData();
    }, intervalInMilliseconds);
  }

  public destroyed() {
    this.stopPulling();
  }

  public get showErrorLabel() {
    return this.showErrors
      ? this.$t("common.portal.batch-register.hide-errors")
      : this.$t("common.portal.batch-register.showErrors");
  }

  public get showErrorIcon(): IconName {
    return this.showErrors ? "chevronUp" : "chevronDown";
  }

  public get failedOnboardingsItems() {
    return this.failedOnboardings.map(failedOnboarding => ({
      ...failedOnboarding,
      reason: this.$t(`common.portal.batch-register.errors.${failedOnboarding.reason}`),
    }));
  }

  public get overall(): number {
    if (!this.progress || this.progress.total == 0) {
      return 0;
    }
    const overall =
      ((this.progress.completed + this.progress.rejected + this.progress.failed) / this.progress.total) * 100;
    return Math.round(overall);
  }

  public finish(): void {
    this.$router.back();
  }

  public toggleShowErrors(): void {
    if (this.failedOnboardings.length) {
      this.showErrors = !this.showErrors;
    }
  }

  private async pullData(): Promise<void> {
    const data = await this.$services.delegatedOnboardingService.getBatchStatus({ batchId: this.batchId });
    this.progress = data.batchProgress;
    if (data.failedOnboardings) {
      this.failedOnboardings = data.failedOnboardings;
    }
    this.dataLoaded = true;
    if (data.success) {
      this.batchCompleted = true;
      this.status = this.$tc("common.completed");
      if (this.failedOnboardings.length) {
        this.status = this.status.concat(" " + this.$tc("common.portal.batch-register.withErrors"));
      }
      this.stopPulling();
    }
  }

  private stopPulling() {
    if (this.intervalId !== undefined) {
      clearInterval(this.intervalId);
      this.intervalId = undefined;
    }
    if (this.timeoutId !== undefined) {
      clearTimeout(this.timeoutId);
      this.timeoutId = undefined;
    }
  }
}
