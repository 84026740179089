import { helpers, requiredIf, required, email as emailValidationRule } from "@vuelidate/validators";
import { isEmpty } from "@/core/shared/utils";

export const dniOrNieFormatValidator = (idNumber: string) =>
  isEmpty(idNumber) || /^[XYZ]?\d{5,8}[A-Z]$/i.test(idNumber);

const dniOrNieLetter = (idNumber: string): string => {
  const LETTERS = "TRWAGMYFPDXBNJZSQVHLCKET";
  const numbers = idNumber
    .substr(0, idNumber.length - 1)
    .replace("X", "0")
    .replace("Y", "1")
    .replace("Z", "2");

  const position = parseInt(numbers, 10) % 23;

  return LETTERS.substring(position, position + 1);
};

export const dniOrNieLetterValidator = (idNumber: string): boolean => {
  if (isEmpty(idNumber)) {
    return true;
  }

  const letter = idNumber.substr(idNumber.length - 1, 1);
  return letter === dniOrNieLetter(idNumber);
};

export const documentNumberValidator = (docNumber: string): boolean =>
  isEmpty(docNumber) || /^[A-Z0-9]{1,9}$/.test(docNumber);

export const emailValidator = (email: string) => emailValidationRule.$validator(email, undefined, undefined) as boolean;

export { requiredIf, helpers, required };
